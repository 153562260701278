import { defineMessages } from "gatsby-plugin-intl"

const messages = defineMessages({
  testStatusUnknown: "Unknown",
  testStatusCreated: "Created",
  testStatusSent: "Sent",
  testStatusStarted: "Started",
  testStatusFinished: "Finished",
  testStatusCancelled: "Cancelled",
  testStatusArchived: "Archived",
})

export enum Status {
  UNKNOWN = 0,
  CREATED = 10,
  SENT = 20,
  STARTED = 30,
  FINISHED = 40,
  DELETED = 50, // also known as CANCELLED
  ARCHIVED = 90,
}

export const getMessageForCode = (status: Status) => {
  switch (status) {
    case 0:
      return messages.testStatusUnknown
    case 10:
      return messages.testStatusCreated
    case 20:
      return messages.testStatusSent
    case 30:
      return messages.testStatusStarted
    case 40:
      return messages.testStatusFinished
    case 50:
      return messages.testStatusCancelled
    case 90:
      return messages.testStatusArchived
    default:
      return "Unknown"
  }
}
