import React from "react"
import useFormatMessage from "../../../utils/hooks/useFormatMessage"
import { getMessageForCode } from "../../../utils/testStatuses"

const Status = ({ statusid }) => {
  const formatMessage = useFormatMessage()

  switch (statusid) {
    case 10:
      return <span className="text-gray-500 text-xs font-semibold uppercase">{formatMessage(getMessageForCode(statusid))}</span>
    case 20:
      return <span className="text-gray-500 text-xs font-semibold uppercase">{formatMessage(getMessageForCode(statusid))}</span>
    case 30:
      return <span className="text-yellow-500 text-xs font-semibold uppercase">{formatMessage(getMessageForCode(statusid))}</span>
    case 50:
      return <span className="text-red-700 text-xs font-semibold uppercase">{formatMessage(getMessageForCode(statusid))}</span>
    case 40:
      return <span className="text-green-700 text-xs font-semibold uppercase">{formatMessage(getMessageForCode(statusid))}</span>
    case 90:
      return <span className="text-gray-400 text-xs font-semibold uppercase">{formatMessage(getMessageForCode(statusid))}</span>
    case 0:
      return <span className="text-green-700 text-xs font-semibold uppercase">{formatMessage(getMessageForCode(statusid))}</span>
    default:
      return <span className="text-grey-400 text-xs font-semibold uppercase">{formatMessage(getMessageForCode(statusid))}</span>
  }
}

export default Status
