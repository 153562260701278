import React from "react"
import { ClipLoader } from "react-spinners"
import { useThemeColors } from "src/utils/hooks/useThemeColors"

const Button = ({ children, onClick = () => {}, className = "", loading = false, type = "button", primary = false, disabled = false }) => {
  const { regular, dark } = useThemeColors()
  const colors = primary
    ? `${disabled ? "bg-gray-500 border-gray-300" : `bg-${regular} border-${dark}`}  text-gray-200`
    : `${disabled ? "bg-gray-500" : "bg-white"}`
  return (
    <button
      type={type}
      className={`flex justify-center outline-none h-10 items-center py-2 px-4 my-2 rounded border-2 cursor-pointer font-bold flex-shrink text-xs ${colors} ${
        disabled ? "bg-gray-400" : ""
      } ${className}`}
      onClick={loading ? () => {} : onClick}
      disabled={disabled}
    >
      {!loading ? children : <ClipLoader size={20} color="#ddd" />}
    </button>
  )
}

export default Button
