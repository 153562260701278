// Note corrupt data in backend? We have to check for these incorrect dates to ensure we user the appropriate one.
export const getLastUpdatedTimestamp = levelEvaluation => {
  if (
    levelEvaluation.finishedtimestamp !== "0000-00-00 00:00:00+00:00" &&
    levelEvaluation.finishedtimestamp !== null &&
    levelEvaluation.finishedtimestamp !== "-001-11-30T00:00:00+00:00"
  ) {
    return levelEvaluation.finishedtimestamp
  } else if (
    levelEvaluation.startedtimestamp !== "0000-00-00 00:00:00+00:00" &&
    levelEvaluation.startedtimestamp !== "-001-11-30T00:00:00+00:00" &&
    levelEvaluation.startedtimestamp !== null
  ) {
    return levelEvaluation.startedtimestamp
  } else if (
    levelEvaluation.requestsenton !== "0000-00-00 00:00:00+00:00" &&
    levelEvaluation.requestsenton !== "-001-11-30T00:00:00+00:00" &&
    levelEvaluation.requestsenton !== null
  ) {
    return levelEvaluation.requestsenton
  } else if (
    levelEvaluation.requesttimestamp !== "0000-00-00 00:00:00+00:00" &&
    levelEvaluation.requesttimestamp !== "-001-11-30T00:00:00+00:00" &&
    levelEvaluation.requesttimestamp !== null
  ) {
    return levelEvaluation.requesttimestamp
  } else if (levelEvaluation.invitationsenton !== "0000-00-00 00:00:00+00:00" && levelEvaluation.invitationsenton !== null) {
    return levelEvaluation.invitationsenton
  } else {
    return levelEvaluation.invitationtimestamp
  }
}
