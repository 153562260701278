import React, { useState } from "react"
import { useSelector } from "react-redux"
import _ from "lodash"
import PaginatedTable from "../../base/paginated-table"
import { levelEvaluationsSelector } from "../../../store/levelEvaluations/selectors"
import { searchTests, filterOptionsActions, filterOptionsLanguages, filterTests, getLastUpdatedTimestamp } from "./utils/utils"
import headers from "./utils/headers"
import Search from "../../layout/search"
import FilterDropDown from "../../base/filter/filterDropDown"
import { getCodeForLanguageName } from "../../../utils/language"
import { defineMessages } from "gatsby-plugin-intl"
import { companySelector } from "../../../store/company/selectors"
import useFormatMessage from "../../../utils/hooks/useFormatMessage"
import { Status } from "../../../utils/testStatuses"
import DateRangeFilter from "../../../components/base/filter/DateRangeFilter"
import { isAfter, isBefore } from "date-fns"
import atoloLogo from "../../../images/logos/logo.png"
import { useCompanyUUID } from "../../../utils/hooks/useCompanyUUID"
import { isCustomCompanyLogo } from "../../../utils/hooks/useCompanyLogo"
import { CSVDownload } from "./components/CSVDownload"

const messages = defineMessages({
  languageFilterTitle: "Language",
  statusFilterTitle: "Status",
  testLeft: "Tests left:",
  resultsPerCompanyLabel: "Results per company:",
})

const Tests = ({ setParticipantSelected, companyUUID, className, searchQuery, onSearch }) => {
  const [filters, setFilters] = useState({ statusid: [], evaluatedlanguage: [] })

  const formatMessage = useFormatMessage()

  /* Get persons related to company from store */
  const { results, loading } = useSelector(levelEvaluationsSelector(companyUUID))

  const { result: company } = useSelector(companySelector(companyUUID))

  const addOrRemoveFilters = (value, key) => {
    if (filters[key].includes(value)) {
      setFilters({ ...filters, [key]: _.filter(filters[key], v => v !== value) })
    } else {
      setFilters({ ...filters, [key]: [...filters[key], value] })
    }
  }

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  /* Parse tests array 
     We select the attributes from the nested person objects 
     and link the company name to the level evaluation attributes */
  let hasFilters = false
  let tests = results
  if (tests && tests.length > 0) {
    if (searchQuery !== "") {
      tests = searchTests(searchQuery, tests)
    }
    hasFilters = filters.statusid.length > 0 || filters.evaluatedlanguage.length > 0
    if (hasFilters) {
      tests = filterTests(filters, tests)
    }

    // check if we are filtering based on date range
    // if so, filter tests based on this range
    if (startDate) {
      tests = tests.filter(test => {
        const ts = getLastUpdatedTimestamp(test)
        if (ts) {
          const date = new Date(ts)
          return isBefore(startDate, date)
        } else {
          return false
        }
      })
    }

    // check if we are filtering based on date range
    // if so, filter tests based on this range
    if (endDate) {
      tests = tests.filter(test => {
        const ts = getLastUpdatedTimestamp(test)
        if (ts) {
          const date = new Date(ts)
          return isAfter(endDate, date)
        } else {
          return false
        }
      })
    }

    // IMPORTANT: Don't show deleted or archived tests, UNLESS filters are active.
    // In case filters are active, we also want to show the archived tests.
    tests =
      tests?.filter(test => {
        if (test.statusid === Status.DELETED) {
          return false
        }
        return hasFilters || test.statusid !== Status.ARCHIVED
      }) ?? []
  }

  const filterObjects = [
    {
      title: messages.languageFilterTitle,
      onChange: value => addOrRemoveFilters(getCodeForLanguageName(value), "evaluatedlanguage"),
      options: filterOptionsLanguages,
    },
    {
      title: messages.statusFilterTitle,
      onChange: value => addOrRemoveFilters(value, "statusid"),
      options: filterOptionsActions,
    },
  ]

  const uuid = useCompanyUUID()
  const showExtraAtolo = isCustomCompanyLogo(uuid)

  const isFiltered = searchQuery || hasFilters || startDate || endDate
  const testsPerCompany = _.groupBy(tests, t => t?.person?.theirref1)
  const companies = Object.keys(testsPerCompany).filter(k => k && (k.length > 0) & (testsPerCompany[k].length > 0))
  const counts = companies.map(key => `${key === "null" ? "Other" : key}: ${testsPerCompany[key].length}`)

  return (
    <div className={`flex flex-col  ${className}`}>
      <div className="flex flex-row  items-center mb-5">
        <div className="flex h-full flex-1 items-center">
          <Search onSearch={onSearch} value={searchQuery} className="mr-5" />
          <FilterDropDown filters={filterObjects} active={filters.statusid.length > 0 || filters.evaluatedlanguage.length > 0} className="mr-2" />
          <DateRangeFilter
            from={startDate}
            until={endDate}
            onRangeChange={(start, end) => {
              setStartDate(start)
              setEndDate(end)
            }}
          />
          <CSVDownload tests={tests} />
          <div className="flex flex-1 " />
          {showExtraAtolo && (
            <img className="p-0 m-0 cursor-pointer -my-5 -mx-3" style={{ width: 120, objectFit: "contain" }} src={atoloLogo} alt="atolo" />
          )}
        </div>
      </div>
      {isFiltered && tests.length > 0 && (
        <div className="flex flex-col mb-4">
          <div className="pb-2 pl-2 pr-2 text-xs opacity-50">{formatMessage(messages.resultsPerCompanyLabel)}</div>
          <div className="flex flex-wrap items-center space-x-1 pb-4">
            {counts.map(count => (
              <div key={count} className="border h-8 px-2 py-1 rounded-full text-sm font-medium">
                {count}
              </div>
            ))}
          </div>
        </div>
      )}
      <PaginatedTable
        data={tests}
        headers={headers}
        onRowClick={setParticipantSelected}
        loading={loading}
        searchQuery={searchQuery}
        defaultSort={[{ header: headers[5], direction: "desc" }]}
      />
    </div>
  )
}

export default Tests
