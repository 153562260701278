import React, { useState } from "react"
import { MdEdit } from "react-icons/md"
import profileImage from "./assets/user.png"
import EditProfileModal from "./components/editProfileModal"

const Profile = ({ person }) => {
  const [edit, setEdit] = useState(false)
  return (
    <>
      <div className="flex flex-row items-center justify-center mb-10">
        <div className="w-12 h-12 md:w-20 md:h-20 ">
          <img src={profileImage} className="w-full h-full opacity-75" />
        </div>
        <div className="flex flex-col ml-6">
          <div className="w-full flex flex-row items-center justify-center">
            <div className="md:text-l flex-1 font-semibold ellipsis">
              {person.firstname} {person.lastname}
            </div>
            <MdEdit className="ml-2 opacity-25 cursor-pointer" size={14} onClick={() => setEdit(true)} />
          </div>
          <div className="text-sm w-full font-medium text-gray-800">{person.theirref1 || person.company.companyname} </div>
          <a className="w-full text-xs italic flex flex-row underline" href={`mailto:${person.email}`}>
            {person.email}
          </a>
        </div>
      </div>
      {edit && <EditProfileModal onClose={() => setEdit(false)} person={person} />}
    </>
  )
}

export default Profile
