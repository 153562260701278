import React, { useState } from "react"
import useFormatMessage from "../../../utils/hooks/useFormatMessage"

const CheckBox = ({ value, onChange, text, className }) => {
  const [checked, setChecked] = useState()
  const formatMessage = useFormatMessage()

  const handleChange = () => {
    onChange(value)
    setChecked(!checked)
  }
  return (
    <div className={`flex items-center cursor-pointer ${className}`} onClick={handleChange}>
      <input type="checkbox" value={value} checked={checked} className="mr-2" />
      {text && formatMessage(text)}
    </div>
  )
}

export default CheckBox
