import { defineMessages } from "gatsby-plugin-intl"
import React from "react"
import useFormatMessage from "src/utils/hooks/useFormatMessage"
import { useThemeColors } from "src/utils/hooks/useThemeColors"
import ModalContainer from "./modalContainer"

const m = defineMessages({
  actionNotAvailableMessage: "This actions is not available right now...",
})

type Props = {
  visible: boolean
  message?: string
  onClose: () => void

  // backwards compat with old code
  isNestedModal?: false
}

const NoticeModal: React.FC<Props> = props => {
  const { visible, message, onClose, isNestedModal = false } = props
  const t = useFormatMessage()
  const { regular } = useThemeColors()
  return (
    <ModalContainer nestedModal={isNestedModal} visible={visible} onClose={onClose}>
      <div className="w-screen max-w-md px-10 -mt-4 pb-12">
        <p className="text-lg text-left font-medium text-gray-800">{t(m.actionNotAvailableMessage)}</p>
        {message && <p className="pt-4 pb-6 text-left">{message}</p>}

        <button
          type="button"
          onClick={onClose}
          className={`text-center px-12 py-2 borde-sm border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-${regular} focus:outline-none focus:ring-0`}
        >
          OK
        </button>
      </div>
    </ModalContainer>
  )
}

export default NoticeModal
