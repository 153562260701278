import React from "react"
import CheckBox from "../forms/checkBox"

const Filter = ({ options, onChange, className }) => {
  return (
    <div className={`flex flex-col justify-between  ${className}`}>
      {options.map(option => (
        <CheckBox key={option.value} value={option.value} onChange={onChange} text={option.text} />
      ))}
    </div>
  )
}

export default Filter
