import { Status } from "../../../../utils/testStatuses"

export const canView = (status: Status): boolean => {
  return status !== Status.UNKNOWN
}

export const canViewReport = (status: Status): boolean => {
  return status === Status.FINISHED
}

export const canCopyLink = (status: Status): boolean => {
  return status !== Status.UNKNOWN && status < Status.FINISHED
}

export const canDownloadCertificate = (status: Status): boolean => {
  return status === Status.FINISHED
}

export const canSendReminder = (status: Status): boolean => {
  return status !== Status.UNKNOWN && status < Status.FINISHED
}

export const canDelete = (status: Status): boolean => {
  return status !== Status.UNKNOWN && status < Status.STARTED
}

export const canArchive = (status: Status): boolean => {
  return status !== Status.UNKNOWN && status >= Status.STARTED
}
