import React, { useEffect, useState } from "react"
import Title from "../../../../../layout/title"
import TextInput from "../../../../../base/forms/textInput"
import Label from "../../../../../base/forms/label"
import ModalContainer from "../../../../../base/modals/modalContainer"
import Button from "../../../../../base/buttons/regular"
import Loader from "../../../../../base/loaders/loader"
import usePatchPerson from "../../../../../../utils/hooks/usePatchPerson"
import notifications from "../../../../../../utils/notifications"
import useUser from "../../../../../../utils/hooks/useUser"

import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../../../utils/hooks/useFormatMessage"

const messages = defineMessages({
  editPersonsInfoTitle: "Edit {firstname}'s info",
  firstNameLabel: "First name:",
  lastNameLabel: "Last name:",
  companyLabel: "Company:",
  emailLabel: "Email:",
  confirmButton: "Confirm",
})

const EditProfileModal = ({ onClose, person, className }) => {
  const formatMessage = useFormatMessage()

  const [profile, setProfile] = useState()
  const [loading, setLoading] = useState(true)
  const [companyUUID, setCompanyUUID] = useState("")

  const user = useUser()
  useEffect(() => {
    if (user && user.company) {
      setCompanyUUID(user.company.uuid)
    }
  })
  useEffect(() => {
    setProfile(person)
    setLoading(false)
  }, [])

  const handleChange = e => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    })
  }
  const { patchPerson, loading: updateLoading } = usePatchPerson()
  /* Handle update of user information */
  const handleUpdate = async () => {
    try {
      await patchPerson(profile, companyUUID)
      notifications.success("Person updated!", "You have successfully updated this person.")
      onClose()
    } catch (error) {
      notifications.error("Oops! Something went wrong.", error.message, error.details)
    }
  }

  return (
    <ModalContainer nestedModal className={`bg-white w-96 shadow rounded items-center z-50 ${className}`} onClose={onClose}>
      <div className="flex flex-col w-full px-12 pb-5">
        {loading ? (
          <Loader />
        ) : (
          <>
            <Title>{formatMessage(messages.editPersonsInfoTitle, { firstname: person.firstname })}</Title>
            <Label className="mb-4" name={formatMessage(messages.firstNameLabel)}>
              <TextInput placeholder={person.firstname} onChange={handleChange} name="firstname" value={profile.firstname} />
            </Label>
            <Label className="mb-4" name={formatMessage(messages.lastNameLabel)}>
              <TextInput placeholder={person.lastname} onChange={handleChange} name="lastname" value={profile.lastname} />
            </Label>
            <Label className="mb-4" name={formatMessage(messages.companyLabel)}>
              <TextInput placeholder={person.theirref1} onChange={handleChange} name="theirref1" value={profile.theirref1} />
            </Label>
            <Label name={formatMessage(messages.emailLabel)}>
              <TextInput placeholder={person.email} onChange={handleChange} name="email" value={profile.email} />
            </Label>
            <Button primary className="self-center text-center w-3/4 mt-6 mb-4" onClick={handleUpdate} loading={updateLoading}>
              {formatMessage(messages.confirmButton)}
            </Button>
          </>
        )}
      </div>
    </ModalContainer>
  )
}

export default EditProfileModal
