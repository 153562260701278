import React from "react"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../utils/hooks/useFormatMessage"

const Search = ({ className, onSearch, value, extra }) => {
  const messages = defineMessages({
    searchPlaceholder: "Search",
  })
  const formatMessage = useFormatMessage()
  if (extra) {
    return (
      <>
        <div className={`w-64 min-w-xl max-w-full bg-white border border-gray-400 rounded block w-full flex ${className}`}>
          <div className={`px-4 py-2`}>
            <input
              className=" focus:outline-none appearance-none text-sm leading-normal"
              placeholder={formatMessage(messages.searchPlaceholder) + "..."}
              value={value}
              onChange={e => onSearch(e.target.value)}
            />
          </div>
          <div className="flex flex-col">{extra.render()}</div>
        </div>
      </>
    )
  }
  return (
    <>
      <div className={`w-64 min-w-xl max-w-full ${className}`}>
        <input
          className="bg-white focus:outline-none border border-gray-400 rounded py-2 px-4 block w-full appearance-none text-sm leading-normal"
          placeholder={formatMessage(messages.searchPlaceholder) + "..."}
          value={value}
          onChange={e => onSearch(e.target.value)}
        />
      </div>
    </>
  )
}

export default Search
