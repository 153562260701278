import React from "react"
import ReactTooltip from "react-tooltip"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"
import { IoMdEye } from "react-icons/io"
import { useThemeColors } from "src/utils/hooks/useThemeColors"

const messages = defineMessages({
  testNotCompletedReportTooltip: "Test not finished",
  testArchivedTooltip: "Test is archived",
  testCompletedReportTooltip: "Go to Report",
  downloadReportButton: "Report",
})

const ReportAction = ({ token, status, key, className }) => {
  const formatMessage = useFormatMessage()
  const { regular } = useThemeColors()

  return (
    <div key={key} className={className}>
      <ReactTooltip id={`report-disabled`} place="top" type="dark" effect="solid">
        {status === 90 ? formatMessage(messages.testArchivedTooltip) : formatMessage(messages.testNotCompletedReportTooltip)}
      </ReactTooltip>
      <ReactTooltip id={`report-enabled`} place="top" type="dark" effect="solid">
        {formatMessage(messages.testCompletedReportTooltip)}
      </ReactTooltip>
      <a data-for={`${status === 40 ? "report-enabled" : "report-disabled"}`} data-tip>
        <div className={`flex flex-col  ml-2 items-center  cursor-pointer ${status === 40 ? `text-${regular}` : "cursor-not-allowed opacity-50"}`}>
          {status === 40 ? (
            <a
              className="flex flex-col items-center justify-center"
              data-for=""
              target="_blank"
              rel="noopener noreferrer"
              href={`/?levelevaluation=${token}&showReport=true`}
            >
              Report <IoMdEye className=" cursor-pointer" size={20} />
            </a>
          ) : (
            <div className="flex flex-col items-center justify-center opacity-50">
              Report <IoMdEye className="  cursor-pointer" size={20} />
            </div>
          )}
        </div>
      </a>
    </div>
  )
}

export default ReportAction
