import React from "react"
import { defineMessages } from "gatsby-plugin-intl"
import moment from "moment"
import { getLanguageCode } from "../../../../utils/language"
import Status from "../../../base/test/status"
import Score from "../../../base/test/score"
import CertificateAction from "../components/certificateAction"
import ReportAction from "../components/reportAction"
import { getLastUpdatedTimestamp } from "./utils"
import { EvaluationType } from "src/api/types"

const messages = defineMessages({
  languageHeader: "Language",
  statusHeader: "Status",
  durationHeader: "Duration",
  scoreHeader: "Score",
  lastUpdatedHeader: "Updated",
  companyHeader: "Company",
})

// Data key should match key as used in data retrieved from backend. This will be used to match header width and mobile width to correct table cells.
// If column should be hidden in mobile, mobileWidth should be undefined.
export default [
  {
    id: "evaluatedlanguage",
    text: messages.languageHeader,
    noTooltip: true,
    width: "w-2/12",
    mobileWidth: "w-3/12",
    extractAttribute: data => data.evaluatedlanguage,
    render: ({ attribute }) => <span className="text-sm w-6 font-bold text-gray-500">{getLanguageCode(attribute.toUpperCase())}</span>,
  },
  {
    id: "score",
    text: messages.scoreHeader,
    noTooltip: true,
    dataKey: "score",
    width: "w-2/12",
    mobileWidth: "w-2/12",
    extractAttribute: data => data.finallevel,
    render: ({ attribute }) => <Score score={attribute} />,
  },
  {
    id: "Duration",
    text: messages.durationHeader,
    noTooltip: true,
    width: "w-2/12",
    sortableIcon: true,
    extractAttribute: data => {
      let duration = 0
      if (data.totalansweringtime) {
        duration = Math.ceil(data.totalansweringtime / 60)
      }

      return duration
    },
    render: ({ attribute }) => {
      let durationText = "-"
      if (attribute > 0) {
        durationText = `${Math.floor(attribute / 60)}:${Math.floor(attribute % 60) < 10 ? "0" : ""}${Math.floor(attribute % 60)}`
      }

      return <span className="text-sm w-6 font-bold text-gray-500">{durationText}</span>
    },
  },
  {
    id: "status",
    text: messages.statusHeader,
    noTooltip: true,
    width: "w-2/12",
    mobileWidth: "w-3/12",
    extractAttribute: data => data.statusid,
    render: ({ attribute }) => <Status statusid={attribute} />,
  },

  {
    id: "lastupdated",
    text: messages.lastUpdatedHeader,
    noTooltip: true,
    width: "w-2/12",
    mobileWidth: "4/12",
    extractAttribute: data => getLastUpdatedTimestamp(data),
    render: ({ data }) => (
      <span className="text-gray-500 text-xs font-semibold uppercase">{moment(getLastUpdatedTimestamp(data)).format("MMM D [at] HH:mm")}</span>
    ),
  },
  {
    id: "action",
    noTooltip: true,
    notClickable: true,
    width: "w-2/12",
    render: ({ data }) => {
      return (
        <div className="flex flex-row items-baseline justify-center pr-2 -mt-2">
          {data?.letypecode !== EvaluationType.EASY && <CertificateAction token={data.token} status={data.statusid} key={data.token} />}
          <ReportAction token={data.token} status={data.statusid} key={data.token} />
        </div>
      )
    },
  },
]
