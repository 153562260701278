import React from "react"

const Score = ({ score }) => {
  let s = score
  if (!s) {
    return <span className="text-gray-500 text-sm font-bold uppercase">-</span>
  }
  return <span className="text-gray-800 text-sm font-bold uppercase">{s}</span>
}

export default Score
