import React from "react"
import { IoMdCloudDownload } from "react-icons/io"
import { useThemeColors } from "src/utils/hooks/useThemeColors"

const CertificateAction = ({ token, status }) => {
  const { regular } = useThemeColors()
  return (
    <a data-for={`${status === 40 ? "certificate-enabled" : "certificate-disabled"}`} data-tip>
      <div className={`flex flex-row items-center  cursor-pointer ${status === 40 ? `text-${regular}` : "cursor-not-allowed opacity-50"}`}>
        {status === 40 ? (
          <a data-for="" target="_blank" rel="noopener noreferrer" href={`/certificate?levelevaluation=${token}`}>
            <div className="flex flex-col items-center cursor-pointer">
              Certificate <IoMdCloudDownload size={16} />
            </div>
          </a>
        ) : (
          <div className="flex flex-col items-center cursor-pointer opacity-50 ">
            Certificate <IoMdCloudDownload size={16} />
          </div>
        )}
      </div>
    </a>
  )
}

export default CertificateAction
