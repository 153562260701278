import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { fetchPerson } from "../../../store/person/actions"
import { personSelector } from "../../../store/person/selectors"
import Profile from "./components/Profile"
import headers from "./utils/headers"
import PaginatedTable from "../../base/paginated-table"
import ModalContainer from "../../base/modals/modalContainer"
import { useDispatch } from "react-redux"

const Participant = ({ className, onClose, visible = false, personUUID }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (personUUID) {
      dispatch(fetchPerson(personUUID))
    }
  }, [personUUID])

  /* Get person selected from store */
  const { result: person, loading } = useSelector(personSelector(personUUID))

  /* Parse the person's level evaluation (select and format required attrs) */
  let levelEvaluations = person && person.levelevaluations

  return (
    <ModalContainer visible={visible} onClose={onClose} className={`md:w-5/3  ${className}`}>
      <div className="flex flex-col items-center w-full pb-10 my-2">
        {person && <Profile person={person} />}
        <div className="flex flex-col w-full">
          <div className="flex flex-col mx-5 pb-10 lg:w-auto rounded h-3/4 overflow-auto bg-scroll overflow-x-hidden ">
            {levelEvaluations && <PaginatedTable data={levelEvaluations} noGreenLine headers={headers} loading={loading} rowsPerPage={8} />}
          </div>
        </div>
      </div>
    </ModalContainer>
  )
}

export default Participant
