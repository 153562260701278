import React, { useState } from "react"
import Filter from "./filter"
import BlackOverlay from "../../layout/blackOverlay"
import useFormatMessage from "../../../utils/hooks/useFormatMessage"
import { FiFilter } from "react-icons/fi"
import { defineMessages } from "gatsby-plugin-intl"
import { useThemeColors } from "src/utils/hooks/useThemeColors"

const messages = defineMessages({
  filterPlaceholder: "Filter",
})

const FilterDropDown = ({ filters, active, className }) => {
  const [visible, setVisible] = useState(false)
  const formatMessage = useFormatMessage()
  const { regular } = useThemeColors()

  return (
    <>
      <div className={` h-full -py-2 ${className}`}>
        <BlackOverlay visible={visible} onClose={() => setVisible(false)} transparent />
        <div
          className="flex items-center py-2 border border-gray-400 rounded px-3 text-sm text-gray-600 cursor-pointer"
          onClick={() => setVisible(!visible)}
        >
          {formatMessage(messages.filterPlaceholder)}
          <FiFilter className={`ml-2 fill-current  ${active ? `text-${regular}` : "text-gray-400"}`} />
        </div>
        <div
          className={`${visible ? "flex" : "hidden"} flex-grow mb-5 absolute bg-white p-4 border rounded shadow z-50`}
          // onClick={() => setTimeout(() => setVisible(false), 100)}
        >
          <div className="mr-2 ">
            {filters.map(filter => (
              <div className="mb-3" key={filter.title.id}>
                <span className="text-sm font-semibold" onClick={filter.onChange}>
                  {formatMessage(filter.title)}
                </span>
                <Filter options={filter.options} onChange={filter.onChange} className="flex-1 text-sm" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default FilterDropDown
