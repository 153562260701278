import React from "react"
import _ from "lodash"
import Action from "../components/Actions"
import Status from "../../../base/test/status"
import Score from "../../../base/test/score"
import { getLanguageCode } from "../../../../utils/language"
import { defineMessages } from "gatsby-plugin-intl"
import { getLastUpdatedTimestamp } from "./utils"
import { format } from "date-fns"
import { EvaluationType, LevelEvaluation } from "src/api/types"
import { getLevelEvaluationTypeNameForType } from "src/utils/evaluation-type-utils"
import { useThemeColors } from "src/utils/hooks/useThemeColors"

const messages = defineMessages({
  nameHeader: "Name",
  durationHeader: "Duration",
  languageHeader: "Language",
  scoreHeader: "Score",
  statusHeader: "Status",
  lastUpdatedHeader: "Updated",
  companyHeader: "Company",
  actionsHeader: "Actions",
})

type Attribute = string | null

type Header = {
  id: string // the ID of the column
  text?: string // the name of displayed on the header
  width: string // the width as a TW class
  mobileWidth?: string // the width on mobile as a TW class
  sortableIcon?: boolean // whether or not the column is sortable
  noTooltip?: boolean // whether or not a tooltip should be shown
  notClickable?: boolean // whether or not this header is clickable
  extractAttribute?: (data: LevelEvaluation) => Attribute // extracts the attribute from the object
  render: React.FC<{ data: LevelEvaluation; attribute: Attribute }> // functional component how to render the child
}

const headers: Header[] = [
  {
    id: "Name",
    text: messages.nameHeader,
    width: "w-3/12",
    sortableIcon: true,
    mobileWidth: "w-4/12",
    extractAttribute: data => data.person.firstname + " " + data.person.lastname,
    render: ({ data, attribute }) => {
      const pts = attribute ? attribute.split(" ") : []
      const fname = _.head(pts)
      const lname = _.tail(pts).join(" ")

      const evalType = data?.letypecode ?? EvaluationType.STANDARD
      const showBadge = evalType !== EvaluationType.STANDARD
      const badgeContent = _.startCase(getLevelEvaluationTypeNameForType(evalType))

      const { regular } = useThemeColors()

      return (
        <div className="flex items-center justify-center">
          <span className="text-gray-700 pr-1">{fname}</span>
          <span className="text-gray-900 font-medium">{lname}</span>
          {showBadge && <span className={`text-xs font-medium mx-1 text-${regular}`}>({badgeContent})</span>}
        </div>
      )
    },
  },
  {
    id: "LANG",
    text: messages.languageHeader,
    width: "w-1/12",
    sortableIcon: true,
    extractAttribute: data => getLanguageCode(data.evaluatedlanguage.toUpperCase()),
    render: ({ attribute }) => <span className="text-sm w-6 font-bold text-gray-800">{attribute}</span>,
  },
  {
    id: "Score",
    text: messages.scoreHeader,
    width: "w-1/12",
    sortableIcon: true,
    extractAttribute: data => data.finallevel,
    render: ({ attribute }) => <Score score={attribute} />,
  },

  {
    id: "Duration",
    text: messages.durationHeader,
    width: "w-1/12",
    sortableIcon: true,
    noTooltip: true,
    extractAttribute: data => {
      const time = data.totalansweringtime ?? 0
      const duration = time ? Math.ceil(time / 60) : 0
      return `${duration}`
    },
    render: ({ attribute }) => {
      const time = attribute ? parseInt(attribute, 10) : 0
      const duration = time > 0 ? `${Math.floor(time / 60)}:${Math.floor(time % 60) < 10 ? "0" : ""}${Math.floor(time % 60)}` : "-"
      return <span className="text-sm w-6 font-bold text-gray-500">{duration}</span>
    },
  },
  {
    id: "Status",
    text: messages.statusHeader,
    width: "w-2/12",
    sortableIcon: true,
    noTooltip: true,
    extractAttribute: data => data.statusid.toString(),
    render: ({ attribute }) => <Status statusid={parseInt(attribute ?? "0", 10)} />,
  },
  {
    id: "Updated",
    text: messages.lastUpdatedHeader,
    width: "w-2/12",
    sortableIcon: true,
    noTooltip: true,
    extractAttribute: data => getLastUpdatedTimestamp(data),
    render: ({ attribute }) => {
      const date = attribute ? new Date(attribute) : null
      const timeString = date ? format(date, "PP, HH:mm") : ""
      return <span className="text-gray-700 text-xs font-medium">{timeString}</span>
    },
  },
  {
    id: "Business",
    text: messages.companyHeader,
    width: "w-1/12",
    noTooltip: true,
    sortableIcon: true,
    extractAttribute: data => data.person.theirref1,
    render: ({ attribute }) => <span className="text-sm w-6  text-gray-800">{attribute}</span>,
  },
  {
    id: "action",
    text: messages.actionsHeader,
    width: "w-1/12",
    noTooltip: true,
    notClickable: true,
    sortableIcon: false,
    render: ({ data }) => <Action status={data.statusid} row={data} uuid={data.uuid} />,
  },
]

export default headers
