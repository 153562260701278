import React from "react"

const Title = props => <h1 className={titleStyle() + props.className}>{props.children}</h1>

export default Title

const titleStyle = () => `
    font-sans
    text-xl
    text-center
    font-semibold
    text-gray-800
    pb-8
`
