import React from "react"
import { CSVLink } from "react-csv"
import { CloudDownloadIcon } from "@heroicons/react/solid"
import { LevelEvaluation } from "../../../../api/types"
import { getMessageForCode } from "../../../../utils/testStatuses"

type Props = {
  tests: LevelEvaluation[]
}

const HEADERS = [
  {
    key: "firstName",
    label: "First Name",
  },
  {
    key: "lastName",
    label: "Last Name",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "started",
    label: "Started",
  },
  {
    key: "finished",
    label: "Finished",
  },
  {
    key: "score",
    label: "Score",
  },
]

export const CSVDownload: React.FC<Props> = props => {
  const data = (props.tests ?? []).map(test => {
    return {
      firstName: test?.person?.firstname,
      lastName: test?.person?.lastname,
      email: test?.person?.email,
      // @ts-ignore
      status: getMessageForCode(test?.statusid)?.defaultMessage,
      started: test?.startedtimestamp,
      finished: test?.finishedtimestamp,
      score: test?.finallevel || "",
    }
  })

  return (
    <CSVLink data={data} headers={HEADERS} filename="atolo-filtered-export.csv" target="_blank">
      <div className="ml-2 flex items-center py-2 border border-gray-400 rounded px-3 text-sm text-gray-600 cursor-pointer">
        Export <CloudDownloadIcon className="ml-2 w-4 h-4 fill-current text-gray-400" />
      </div>
    </CSVLink>
  )
}
