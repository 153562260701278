import React, { useState } from "react"
import ConfirmModal from "../../../base/modals/confirmModal"
import useDeleteLevelEvaluation from "../../../../utils/hooks/useDeleteLevelEvaluation"
import notifications from "../../../../utils/notifications"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "../../../../utils/hooks/useFormatMessage"
import useSendReminder from "../../../../utils/hooks/useSendReminder"
import { Dropdown } from "./Dropdown"
import { ArchiveIcon, BellIcon, ClipboardCopyIcon, DocumentReportIcon, DownloadIcon, TrashIcon } from "@heroicons/react/solid"
import { canArchive, canCopyLink, canDelete, canDownloadCertificate, canSendReminder, canViewReport } from "./Actions.utils"
import NoticeModal from "../../../../components/base/modals/NoticeModal"
import copy from "copy-to-clipboard"
import { EvaluationType } from "src/api/types"

const messages = defineMessages({
  sendReminderTooltip: "Last reminder sent on ",
  noReminderSentTooltip: "No reminders sent so far.",
  sendReminderSuccesNotificationTitle: "Reminder send",
  sendReminderSuccesNotificationMessage: "An email was sent to the person related to this test.",
  sendReminderErrorNotificationTitle: "Oops! Something went wrong.",
  deleteLevelEvaluationErrorNotificationTitle: "Oops! Something went wrong.",
  deleteLevelEvaluationSuccesNotificationTitle: "Test deleted!",
  deleteLevelEvaluationSuccesNotificationMessage: "You have sucessfully deleted this test.",
  archiveLevelEvaluationErrorNotificationTitle: "Oops! Something went wrong.",
  archiveLevelEvaluationSuccesNotificationTitle: "Test archived!",
  archiveLevelEvaluationSuccesNotificationMessage: "You have sucessfully archived this test.",
  unableToViewTest:
    "It is only possible to view the report of a test that is already finished. When a test is not finished, is deleted, or is archived, you can not view its report.",
  unableToDownloadCertificate:
    "It is only possible to download the certificate of a test that is already finished. When a test is not finished, is deleted, or is archived, you can not download its certificate.",
  unableToDownloadCertificateForEasyTest:
    "It is not possible to download a certificate of a test of this type. If you need a certificate, please do a test of another type or contact Atolo.",
  unableToCopyLink:
    "You can only copy the linkt to a test when the test is not finished yet. Once a test is finished, you cannot use its link anymore and can only view the report or the certificate.",
  unableToSendReminder:
    "You can only send an e-mail reminder to a participant when the test is not finished yet. When a test is finsihed, deleted, or archived, you can not send a new reminder to the participant.",
  unableToArchive:
    "You can only archive tests which participants have already started. When a test is not yet started, you should delete it instead.",
  unableToDelete:
    "You can only delete tests which participants have not already started. When a test is already started, you should archive it instead.",
  cancelledText: "Cancelled",
  removeTooltip: "Remove",
  archiveTooltip: "Archive",
  confirmDeleteMessage: "Are you sure you want to delete this level evaluation?",
  confirmDeleteButton: "DELETE",
  confirmArchiveMessage: "Are you sure you want to archive this level evaluation?",
  confirmArchiveButton: "ARCHIVE",
})

const Action: React.FC<any> = props => {
  const { row, status } = props

  const token: string = row.token

  const [dialogMessage, setDialogMessage] = useState<string | null>(null)

  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmArchive, setConfirmArchive] = useState(false)

  /* Delete level evaluations */
  const { deleteLevelEvaluation } = useDeleteLevelEvaluation()
  const { sendReminder } = useSendReminder()

  const isEasy = row?.letypecode === EvaluationType.EASY

  const handleDelete = async () => {
    try {
      await deleteLevelEvaluation(row.uuid, row.person.companyuuid)
      notifications.success(
        formatMessage(messages.deleteLevelEvaluationSuccesNotificationTitle),
        formatMessage(messages.deleteLevelEvaluationSuccesNotificationMessage)
      )
      setConfirmDelete(false)
    } catch (error) {
      setConfirmDelete(false)
      //@ts-ignore
      notifications.error(formatMessage(messages.deleteLevelEvaluationErrorNotificationTitle), error.message, error.details)
    }
  }

  const handleArchive = async () => {
    try {
      await deleteLevelEvaluation(row.uuid, row.person.companyuuid)
      notifications.success(
        formatMessage(messages.archiveLevelEvaluationSuccesNotificationTitle),
        formatMessage(messages.archiveLevelEvaluationSuccesNotificationMessage)
      )
      setConfirmDelete(false)
    } catch (error) {
      setConfirmDelete(false)
      //@ts-ignore
      notifications.error(formatMessage(messages.archiveLevelEvaluationErrorNotificationTitle), error.message, error.details)
    }
  }

  const handleSendReminder = async () => {
    try {
      await sendReminder(row)
      notifications.success(
        formatMessage(messages.sendReminderSuccesNotificationTitle),
        formatMessage(messages.sendReminderSuccesNotificationMessage)
      )
    } catch (err) {
      //@ts-ignore
      notifications.error(formatMessage(messages.sendReminderErrorNotificationTitle), err.message, err.details)
    }
  }

  const formatMessage = useFormatMessage()

  return (
    <>
      <NoticeModal visible={!!dialogMessage} message={dialogMessage ?? undefined} onClose={() => setDialogMessage(null)} />
      <ConfirmModal
        visible={confirmDelete}
        title={formatMessage(messages.confirmDeleteMessage)}
        confirmText={formatMessage(messages.confirmDeleteButton)}
        onConfirm={handleDelete}
        onClose={() => setConfirmDelete(false)}
      />
      <ConfirmModal
        visible={confirmArchive}
        title={formatMessage(messages.confirmArchiveMessage)}
        confirmText={formatMessage(messages.confirmArchiveButton)}
        onConfirm={handleArchive}
        onClose={() => setConfirmArchive(false)}
      />
      <Dropdown
        options={[
          {
            id: "copy-to-clipboard",
            name: "Copy test link",
            Icon: ClipboardCopyIcon,
            disabled: !canCopyLink(status),
            onClick: () => {
              if (!canCopyLink(status)) {
                setDialogMessage(formatMessage(messages.unableToCopyLink))
              } else {
                const origin = window?.location?.origin ?? document?.location?.origin
                copy(`${origin}/?levelevaluation=${token}`)
              }
            },
          },
          {
            id: "send-reminder",
            name: "Send reminder",
            Icon: BellIcon,
            disabled: !canSendReminder(status),
            onClick: () => {
              if (canSendReminder(status)) {
                handleSendReminder()
              } else {
                setDialogMessage(formatMessage(messages.unableToSendReminder))
              }
            },
          },
          {
            id: "view-report",
            name: "View report",
            Icon: DocumentReportIcon,
            disabled: !canViewReport(status),
            url: canViewReport(status) ? `/?levelevaluation=${token}&showReport=true` : undefined,
            onClick: () => {
              if (!canViewReport(status)) {
                setDialogMessage(formatMessage(messages.unableToViewTest))
              }
            },
          },
          {
            id: "download-certificate",
            name: "Download certificate",
            Icon: DownloadIcon,
            disabled: !canDownloadCertificate(status),
            url: canDownloadCertificate(status) && !isEasy ? `/certificate?levelevaluation=${token}` : undefined,
            onClick: () => {
              if (isEasy) {
                setDialogMessage(formatMessage(messages.unableToDownloadCertificateForEasyTest))
              } else if (!canDownloadCertificate(status)) {
                setDialogMessage(formatMessage(messages.unableToDownloadCertificate))
              }
            },
          },
          {
            id: "archive",
            name: "Archive",
            Icon: ArchiveIcon,
            disabled: !canArchive(status),
            onClick: () => {
              if (canArchive(status)) {
                setConfirmArchive(true)
              } else {
                setDialogMessage(formatMessage(messages.unableToArchive))
              }
            },
          },
          {
            id: "delete",
            name: "Delete",
            Icon: TrashIcon,
            disabled: !canDelete(status),
            onClick: () => {
              if (canDelete(status)) {
                setConfirmDelete(true)
              } else {
                setDialogMessage(formatMessage(messages.unableToDelete))
              }
            },
          },
        ]}
      />
    </>
  )
}

export default Action
