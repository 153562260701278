import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import Tests from "src/components/admin/tests"
import ParticipantModal from "src/components/admin/participantModal"
import Layout from "src/components/layout"
import AdminLoginGuard from "src/components/admin/AdminLoginGuard"
import useUser from "src/utils/hooks/useUser"
import { fetchLevelEvaluations } from "src/store/levelEvaluations/actions"
import { fetchCompany } from "src/store/company/actions"
import { useDispatch } from "react-redux"

const TestsPage = () => {
  const [participantSelected, setParticipantSelected] = useState()
  const [searchQuery, setSearchQuery] = useState("")
  const [companyUUID, setCompanyUUID] = useState("")

  const user = useUser()
  const dispatch = useDispatch()

  useEffect(() => {
    if (user && user.company) {
      setCompanyUUID(user.company.uuid)
    }
  }, [user])

  useEffect(() => {
    if (companyUUID) {
      dispatch(fetchLevelEvaluations(companyUUID))
      dispatch(fetchCompany(companyUUID))
    }
  }, [companyUUID])

  const handleParticipantClick = personUUID => {
    setParticipantSelected(personUUID)
  }

  return (
    <AdminLoginGuard>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atolo - Eval | Admin</title>
      </Helmet>
      <Layout type="hr" onSearch={setSearchQuery} searchQuery={searchQuery}>
        <div className="container flex justify-center flex-1 h-full max-w-4xl w-4/5 md:h-auto pt-5 md:pb-20">
          <ParticipantModal
            visible={participantSelected}
            className="bg-white absolute md:w-8/12 md:h-auto"
            onClose={() => setParticipantSelected(undefined)}
            personUUID={participantSelected}
          />
          <Tests
            className="w-full"
            setParticipantSelected={handleParticipantClick}
            companyUUID={companyUUID}
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
          />
        </div>
      </Layout>
    </AdminLoginGuard>
  )
}

export default TestsPage
