import React from "react"
import DatePicker from "react-datepicker"
import { CalendarIcon, XCircleIcon } from "@heroicons/react/solid"
import { format } from "date-fns"
import { defineMessages } from "gatsby-plugin-intl"
import useFormatMessage from "src/utils/hooks/useFormatMessage"

const messages = defineMessages({
  dateRangeFilterButton: "Dates",
})

type Props = {
  from?: Date
  until?: Date
  onRangeChange?: (from?: Date, until?: Date) => void
}

const DateRangeFilter: React.FC<Props> = props => {
  const { from, until, onRangeChange } = props

  const formatMessage = useFormatMessage()

  const onChange = (dates: any) => {
    if (onRangeChange) {
      const [start, end] = dates
      onRangeChange(start, end)
    }
  }

  const startString = from ? format(from, "dd/MM/''yy") : "..."
  const endString = until ? format(until, "dd/MM/''yy") : "..."
  const rangeString = `${startString} - ${endString}`

  const isActive = !!from || !!until

  return (
    <>
      <div className="h-full -py-2">
        <DatePicker
          selected={from}
          onChange={onChange}
          startDate={from}
          endDate={until}
          selectsRange
          customInput={
            <div className="flex items-center py-2 border border-gray-400 rounded px-3 text-sm text-gray-600 cursor-pointer">
              {isActive ? rangeString : formatMessage(messages.dateRangeFilterButton)}
              {isActive ? (
                <XCircleIcon
                  className="ml-1 w-4 h-4 cursor-pointer text-gray-400"
                  onClick={e => {
                    e.stopPropagation()
                    onChange([null, null])
                  }}
                />
              ) : (
                <CalendarIcon className="ml-2 w-4 h-4 text-gray-400" />
              )}
            </div>
          }
        />
      </div>
    </>
  )
}

export default DateRangeFilter
