import { useState } from "react"
import { updateLevelEvaluation } from "src/api"
import { useDispatch } from "react-redux"
import { fetchLevelEvaluations } from "../../store/levelEvaluations/actions"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"
export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const execute = async levelEvaluation => {
    try {
      trackCustomEvent(getAPI_CALL_STARTED("SEND_REMINDER_LEVEL_EVALUATION"))

      setLoading(true)
      setError(undefined)
      const data = await updateLevelEvaluation({ ...levelEvaluation, sendReminder: true })
      dispatch(fetchLevelEvaluations(levelEvaluation.person.companyuuid))
      return data
    } catch (error) {
      Sentry.captureException(error)

      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, sendReminder: execute }
}
