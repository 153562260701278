import { useState } from "react"
import { deleteLevelEvaluation } from "src/api"
import { fetchLevelEvaluations } from "../../store/levelEvaluations/actions"
import { useDispatch } from "react-redux"
import { fetchCompany } from "../../store/company/actions"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { getAPI_CALL_STARTED } from "../gaConstants"
export default () => {
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const execute = async (levelEvaluationUUID, companyUUID) => {
    try {
      trackCustomEvent(getAPI_CALL_STARTED("DELETE_LEVEL_EVALUATION"))

      setLoading(true)
      setError(undefined)
      const data = await deleteLevelEvaluation(levelEvaluationUUID)
      dispatch(fetchLevelEvaluations(companyUUID))
      dispatch(fetchCompany(companyUUID))
      return data
    } catch (error) {
      Sentry.captureException(error)

      setError(error.message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { error, loading, deleteLevelEvaluation: execute }
}
