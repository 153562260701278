import _ from "lodash"
import { getMessageForCode } from "../../../../utils/testStatuses"
import { defineMessages } from "gatsby-plugin-intl"

const messages = defineMessages({
  dutchFilterOption: "Dutch",
  germanFilterOption: "German",
  englishFilterOption: "English",
  frenchFilterOption: "French",
})

// Note corrupt data in backend? We have to check for these incorrect dates to ensure we user the appropriate one.
export const getLastUpdatedTimestamp = levelEvaluation => {
  if ([0, 10].includes(levelEvaluation.statusid)) {
    return levelEvaluation.requesttimestamp
  } else if (levelEvaluation.statusid === 20) {
    return levelEvaluation.requestsenton
  } else if (levelEvaluation.statusid === 30) {
    return levelEvaluation.startedtimestamp
  } else if (levelEvaluation.statusid === 40) {
    return levelEvaluation.finishedtimestamp
  } else {
    return levelEvaluation.finishedtimestamp || levelEvaluation.startedtimestamp || levelEvaluation.requestsenton || levelEvaluation.requesttimestamp
  }
}

export const searchTests = (searchQuery, tests) => {
  const queryStringsArray = searchQuery.split(" ")
  let result = tests
  queryStringsArray.forEach(queryString => {
    result = _.filter(result, o => {
      return (
        o.person.firstname?.toLowerCase().includes(queryString.trim()?.toLowerCase()) ||
        o.person.lastname?.toLowerCase().includes(queryString.trim()?.toLowerCase()) ||
        o.person.email?.toLowerCase().includes(queryString.trim()?.toLowerCase()) ||
        o.person.theirref2?.toLowerCase().includes(queryString.trim()?.toLowerCase()) ||
        o.person.theirref1?.toLowerCase().includes(queryString.trim()?.toLowerCase())
      )
    })
  })
  return result
}

// filters is an object
// Ex. { statusid: [], evaluatedlanguage: ["Nederlands"]}
export const filterTests = (filters, tests) => {
  let result = tests
  _.forOwn(filters, (allowedValues, propertyToFilter) => {
    if (!allowedValues || allowedValues.length === 0) {
      return
    }

    result = result.filter(test => allowedValues.map(v => String(v).toLowerCase()).includes(String(_.get(test, propertyToFilter, "")).toLowerCase()))
  })

  return result
}

export const filterOptionsActions = [
  {
    text: getMessageForCode(20),
    value: 20,
  },
  {
    text: getMessageForCode(30),
    value: 30,
  },
  {
    text: getMessageForCode(40),
    value: 40,
  },
  {
    text: getMessageForCode(90),
    value: 90,
  },
]

export const filterOptionsLanguages = [
  {
    text: messages.dutchFilterOption,
    value: "Nederlands",
  },
  {
    text: messages.frenchFilterOption,
    value: "Français",
  },
  {
    text: messages.englishFilterOption,
    value: "English",
  },
  {
    text: messages.germanFilterOption,
    value: "Deutsch",
  },
]
